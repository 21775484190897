<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>海外资源</el-breadcrumb-item>
                <el-breadcrumb-item>国家分区及排列</el-breadcrumb-item>
                <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{hzyname}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="text">{{detailsList.name}}</div>
            <div class="button" @click="goUrl(detailsList.link)" v-if="detailsList.link">
                <img src="../../assets/images/button.png" alt="">
                <p>点此进入平台</p>
            </div>
            <div class="tool-p" v-html="detailsList.content"></div>
        </div>
    </div>
</template>

<script>
    import { getdetails ,getcountrys,getplatexts} from "../../api/index"
    export default {
        name:'state_list',
        data() {
            return {
                id:'',
                p_id:'',
                gj_id:'',
                hzyname:'',
                name:'',
                detailsList:[]                
            }
        },
        created() {
            this.id=this.$route.query.id
            this.p_id=this.$route.query.p_id
            this.gj_id=this.$route.query.gj_id
            this.getdetails()

            this.hzycountrys()
            this.getcountrys()
            
        },
        methods: {   
            goUrl(url){
                window.open(url)
            }, 
            // getdetails(){
            //     getdetails({
            //         id:this.id
            //     }).then((res)=>{
            //         // console.log(res)
            //         if (res.code==200) {
            //             this.detailsList=res.data
            //         }
            //     })
            // },
            getdetails(){
                getplatexts({
                    plate_id:this.id,
                    coun_id:this.gj_id,
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                    }
                })
            },
            // 获取国家上级地区
            hzycountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.p_id==res.data[index].id) {
                                this.name=res.data[index].name
                            }
                        }
                    }
                })
            },
            // 获取国家
            getcountrys(){
                getcountrys({
                    p_id:this.p_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        for (let index = 0; index < res.data.length; index++) {
                            if (this.gj_id==res.data[index].id) {
                                this.hzyname=res.data[index].name
                            }
                        }
                        
                    }

                })
            },     
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:70px 100px;box-sizing: border-box;background:#fff;
        .text{font-size: 28px;color: #555555;text-align: center;}
        .button{
            width: 151px;height: 43px;position: relative;margin:30px auto;cursor: pointer;
            img{width: 100%;height: 100%;}
            p{width: 151px;height: 43px;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;line-height: 43px;text-align: center;}
        } 
        .tool-p{
            color: #666666;
            font-size: 16px;
            line-height: 32px;
            img{width: 100%;}
        }

    }

</style>